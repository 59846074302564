import React, { ReactNode } from "react";
import { breakpoint } from "@d2c-ui-components-react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  maxWidth?: string;
  relative?: boolean;
  className?: string;
  id?: string;
}

interface IContentWrapper {
  maxWidth?: string;
}

export const ContentWrapper = styled.div<{
  maxWidth?: string;
  relative?: boolean;
}>`
  ${breakpoint("xxl")`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    max-width: ${(props: IContentWrapper) =>
      props.maxWidth ? props.maxWidth : "1214px"};
    margin: 0 auto;
  `}
  ${(props) => props.relative && "position: relative;"};
`;

export const ContentContainer = styled.div`
  ${breakpoint("xxl")`
    grid-column: 1/13;
  `}
`;

export const MainLayout = ({
  children,
  maxWidth,
  relative,
  className,
  ...restProps
}: Props) => {
  return (
    <ContentWrapper
      {...restProps}
      className={className}
      maxWidth={maxWidth}
      relative={relative}
    >
      <ContentContainer>{children}</ContentContainer>
    </ContentWrapper>
  );
};

export default MainLayout;
