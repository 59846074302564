import { Button } from "@fwd-dep/nextgen-ui-lib";
import { Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setWindowOpen } from "@utils";
import I18nContext from "i18n/context/LanguageContext";
import get from "lodash/get";
import React, { useContext } from "react";
import styled from "styled-components";
import { resolveAbsoluteUrl } from "utils/route";

const ModalBody = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  mstransform: translateY(-50%);
  margin: 0 auto;
  width: auto;
  max-width: 600px;
  max-height: calc(100% - 64px);
`;

const ModalContent = styled.div<{ isMobile: boolean }>`
  background: white;
  border-radius: 16px;
  padding: ${({ isMobile }) => (isMobile ? "24px" : "48px")};
`;

export type ModalPageNotFoundContextType = {
  showModal: () => void;
};

export const ModalPageNotFoundContext = React.createContext(
  {} as ModalPageNotFoundContextType
);

export function ModalPageNotFoundProvider(props: any) {
  const i18nContext = useContext(I18nContext);
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const showModal = () => setOpen(true);

  const data = get(i18nContext, "siteSettings.unsupported_page", {});
  const title = data?.title || "This content might not be in your language.";
  const description =
    data?.description ||
    "We currently working to have this content in your language. You can choose to stay on this page, or proceed to explore other relevant content.";
  const btnPrimaryText = data?.button_primary_text || "Explore";
  const btnSecondaryText = data?.button_secondary_text || "Stay on page";

  return (
    <ModalPageNotFoundContext.Provider
      value={{
        showModal,
      }}
    >
      {props.children}
      <Modal
        open={open}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableBackdropClick={true}
        onClose={() => setOpen(false)}
      >
        <ModalBody>
          <ModalContent isMobile={isMobile}>
            <Typography
              variant="h4"
              style={
                isMobile
                  ? {}
                  : {
                      fontSize: "1.5625rem",
                      fontWeight: 500,
                      lineHeight: "2.375rem",
                      letterSpacing: "0.6px",
                      marginBottom: 0,
                    }
              }
            >
              {title}
            </Typography>
            <p style={{
              marginTop: '8px',
              marginBottom: '24px',
            }}>{description}</p>
            <a href={resolveAbsoluteUrl("/", i18nContext)}>
              <Button
                style={{
                  width: "240px",
                  padding: 0,
                }}
                color="white"
                onClick={() =>
                  setWindowOpen(resolveAbsoluteUrl("/", i18nContext), "_self")
                }
              >
                {btnPrimaryText}
              </Button>
            </a>
            <Button
              color="orange"
              style={{
                border: "2px solid #E87722",
                paddingBottom: "6px",
                paddingTop: "6px",
                marginLeft: "16px",
                width: "240px",
                padding: 0,
              }}
              onClick={() => setOpen(false)}
            >
              {btnSecondaryText}
            </Button>
          </ModalContent>
        </ModalBody>
      </Modal>
    </ModalPageNotFoundContext.Provider>
  );
}
