import dynamic from "next/dynamic";

export const ClaimGuideQuestion = dynamic<any>(() =>
  import("./ClaimGuideQuestion").then((mod) => mod.ClaimGuideQuestion)
);
export const CSStepGuide = dynamic<any>(() =>
  import("./StepGuide").then((mod) => mod.CSStepGuide)
);
export const CSContentSectionV2 = dynamic<any>(() =>
  import("./ContentSectionV2").then((mod) => mod.CSContentSectionV2)
);
export const ChatbotPreview = dynamic<any>(() =>
  import("./ChatbotPreview").then((mod) => mod.ChatbotPreview)
);
export const ChatbotProductList = dynamic<any>(() =>
  import("./ChatbotProductList").then((mod) => mod.ChatbotProductList)
);
export const HeaderSchema = dynamic<any>(() =>
  import("./HeaderSchema").then((mod) => mod.HeaderSchema)
);
export const CarouselVideoBanner = dynamic<any>(() =>
  import("./CarouselVideoBanner").then((mod) => mod.CarouselVideoBanner)
);
export const PodcastList = dynamic<any>(() =>
  import("./PodcastList").then((mod) => mod.PodcastList)
);
export const CardCollection = dynamic<any>(() =>
  import("./CardCollection").then((mod) => mod.CardCollection)
);
export const HighlightBanner = dynamic<any>(() =>
  import("./HighlightBanner").then((mod) => mod.HighlightBanner)
);
export const InsurancePromotions = dynamic<any>(() =>
  import("./InsurancePromotions").then((mod) => mod.InsurancePromotions)
);
export const CustomerInformationBlock = dynamic<any>(() =>
  import("./CustomerInformationBlock").then(
    (mod) => mod.CustomerInformationBlock
  )
);
export const InsuranceTerm = dynamic<any>(() =>
  import("./InsuranceTerm").then((mod) => mod.InsuranceTerm)
);
export const SupportMarketingModule = dynamic<any>(() =>
  import("./SupportMarketingModule").then((mod) => mod.SupportMarketingModule)
);
export const MergedBlock = dynamic<any>(() =>
  import("./MergedBlock").then((mod) => mod.MergedBlock)
);
export const MultiColumnSection = dynamic<any>(() =>
  import("./MultiColumnSection").then((mod) => mod.MultiColumnSection)
);

export const Reminder = dynamic<any>(() =>
  import("./Reminder").then((mod) => mod.Reminder)
);
export const ProductPlanCardList = dynamic<any>(() =>
  import("./ProductPlanCardList").then((mod) => mod.ProductPlanCardList)
);
export const ComparisonTable = dynamic<any>(() =>
  import("./ComparisonTable").then((mod) => mod.ComparisonTable)
);
export const VideoListing = dynamic<any>(() =>
  import("./VideoListing").then((mod) => mod.VideoListing)
);
export const SurveySection = dynamic<any>(() =>
  import("./SurveySection").then((mod) => mod.SurveySection)
);

export const CSInteractiveStepper = dynamic<any>(() =>
  import("./InteractiveStepper").then((mod) => mod.CSInteractiveStepper)
);
export const AnimationContentSection = dynamic<any>(() =>
  import("./AnimationContentSection").then((mod) => mod.AnimationContentSection)
);
export const AnnouncementList = dynamic<any>(() =>
  import("./AnnouncementList").then((mod) => mod.AnnouncementList)
);
export const ArticleBlock = dynamic<any>(() =>
  import("./ArticleBlock").then((mod) => mod.ArticleBlock)
);
export const ArticleCategoryTagList = dynamic<any>(() =>
  import("./ArticleCategoryTagList").then((mod) => mod.ArticleCategoryTagList)
);
export const ArticleGridBlock = dynamic<any>(() =>
  import("./ArticleGridBlock").then((mod) => mod.ArticleGridBlock)
);
export const CSArticlePreview = dynamic<any>(() =>
  import("./ArticlePreview").then((mod) => mod.CSArticlePreview)
);
export const AwardBlock = dynamic<any>(() =>
  import("./AwardBlock").then((mod) => mod.default)
);
export const CampaignCard = dynamic<any>(() =>
  import("./CampaignCard").then((mod) => mod.CampaignCard)
);
export const CarouselBanner = dynamic<any>(() =>
  import("./CarouselBanner").then((mod) => mod.CarouselBanner)
);
export const CategoryBlock = dynamic<any>(() =>
  import("./CategoryBlock").then((mod) => mod.CategoryBlock)
);
export const CategoryCard = dynamic<any>(() =>
  import("./CategoryCard").then((mod) => mod.default)
);
export const CollapsibleTagsBlock = dynamic<any>(() =>
  import("./CollapsibleTagsBlock").then((mod) => mod.CollapsibleTagsBlock)
);
export const ContentBlock = dynamic<any>(() =>
  import("./ContentBlock").then((mod) => mod.ContentBlock)
);
export const ContentBlockContainer = dynamic<any>(() =>
  import("./ContentBlockContainer").then((mod) => mod.default)
);
export const ContentBlockWithTags = dynamic<any>(() =>
  import("./ContentBlockWithTags").then((mod) => mod.default)
);
export const ContentSnippet = dynamic<any>(() =>
  import("./ContentSnippet").then((mod) => mod.ContentSnippet)
);
export const CoverFlowBlock = dynamic<any>(() =>
  import("./CoverFlowBlock").then((mod) => mod.CoverFlowBlock)
);
export const CustomerSupportBlock = dynamic<any>(() =>
  import("./CustomerSupportBlock").then((mod) => mod.CustomerSupportBlock)
);
export const Datatable = dynamic<any>(() =>
  import("./Datatable").then((mod) => mod.Datatable)
);
export const DisclosurePopup = dynamic<any>(() =>
  import("./DisclosurePopup").then((mod) => mod.DisclosurePopup)
);
export const Divider = dynamic<any>(() =>
  import("./Divider").then((mod) => mod.Divider)
);
export const CSDownloadList = dynamic<any>(() =>
  import("./DownloadList").then((mod) => mod.CSDownloadList)
);
export const DynamicCardList = dynamic<any>(() =>
  import("./DynamicCardList").then((mod) => mod.DynamicCardList)
);
export const EmptyState = dynamic<any>(() =>
  import("./EmptyState").then((mod) => mod.default)
);
export const CSEnquiryFormBlock = dynamic<any>(() =>
  import("./EnquiryForm").then((mod) => mod.CSEnquiryFormBlock)
);
export const CSExchangeRate = dynamic<any>(() =>
  import("./ExchangeRate").then((mod) => mod.CSExchangeRate)
);
export const CSFAQBlock = dynamic<any>(() =>
  import("./FAQBlock").then((mod) => mod.CSFAQBlock)
);
export const FAQList = dynamic<any>(() =>
  import("./FAQList").then((mod) => mod.FAQList)
);
export const FlexiContainerBlock = dynamic<any>(() =>
  import("./FlexiContainerBlock").then((mod) => mod.FlexiContainerBlock)
);
export const CSFlexiContainerBlock = dynamic<any>(() =>
  import("./FlexiContainerBlock").then((mod) => mod.CSFlexiContainerBlock)
);
export const CSFlexiForm = dynamic<any>(() =>
  import("./FlexiForm").then((mod) => mod.CSFlexiForm)
);
export const Gallery = dynamic<any>(() =>
  import("./Gallery").then((mod) => mod.Gallery)
);
export const CSGlobalBlock = dynamic<any>(() =>
  import("./GlobalBlock").then((mod) => mod.CSGlobalBlock)
);
export const GroupInsurance = dynamic<any>(() =>
  import("./GroupInsurance").then((mod) => mod.GroupInsurance)
);
export const HeaderMetadata = dynamic<any>(() =>
  import("./HeaderMetadata").then((mod) => mod.HeaderMetadata)
);
export const CSHeroBanner = dynamic<any>(() =>
  import("./HeroBanner").then((mod) => mod.CSHeroBanner)
);
export const HeroBlock = dynamic<any>(() =>
  import("./HeroBlock").then((mod) => mod.HeroBlock)
);
export const HistoricalFundPrice = dynamic<any>(() =>
  import("./HistoricalFundPrice").then((mod) => mod.HistoricalFundPrice)
);
export const HistoricalPriceList = dynamic<any>(() =>
  import("./HistoricalPriceList").then((mod) => mod.HistoricalPriceList)
);
export const HorizontalTab = dynamic<any>(() =>
  import("./HorizontalTab").then((mod) => mod.HorizontalTab)
);
export const CsIframe = dynamic<any>(() =>
  import("./Iframe").then((mod) => mod.CsIframe)
);
export const LegacyProductListBlock = dynamic<any>(() =>
  import("./LegacyProductListBlock").then((mod) => mod.LegacyProductListBlock)
);
export const LinkListBlock = dynamic<any>(() =>
  import("./LinkListBlock").then((mod) => mod.LinkListBlock)
);
export const Locator = dynamic<any>(() =>
  import("./Locator").then((mod) => mod.Locator)
);
export const MainLayout = dynamic<any>(() =>
  import("./MainLayout").then((mod) => mod.MainLayout)
);
export const MainProductLayout = dynamic<any>(() =>
  import("./MainProductLayout").then((mod) => mod.MainProductLayout)
);
export const ManagementTeamCard = dynamic<any>(() =>
  import("./ManagementTeamCard").then((mod) => mod.default)
);
export const MarketingContentBlock = dynamic<any>(() =>
  import("./MarketingContentBlock").then((mod) => mod.MarketingContentBlock)
);
export const MegaMenu = dynamic<any>(() =>
  import("./MegaMenu").then((mod) => mod.default)
);
export const MoveToTop = dynamic<any>(() =>
  import("./MoveToTop").then((mod) => mod.default)
);
export const MoveToTopIcon = dynamic<any>(() =>
  import("./MoveToTopIcon").then((mod) => mod.MoveToTopIcon)
);
export const MultipleContactBlock = dynamic<any>(() =>
  import("./MultipleContactBlock").then((mod) => mod.MultipleContactBlock)
);
export const OneWebCardList = dynamic<any>(() =>
  import("./OneWebCardList").then((mod) => mod.OneWebCardList)
);
export const CSContentSnippet = dynamic<any>(() =>
  import("./OnewebContentSnippet").then((mod) => mod.CSContentSnippet)
);
export const PartnerBlock = dynamic<any>(() =>
  import("./PartnerBlock").then((mod) => mod.PartnerBlock)
);
export const PlanComparison = dynamic<any>(() =>
  import("./PlanComparison").then((mod) => mod.PlanComparison)
);
export const PriceOfInvestmentChoices = dynamic<any>(() =>
  import("./PriceOfInvestmentChoices").then(
    (mod) => mod.PriceOfInvestmentChoices
  )
);
export const ProdCatetoryCard = dynamic<any>(() =>
  import("./ProdCatetoryCard").then((mod) => mod.default)
);
export const ProductCardList = dynamic<any>(() =>
  import("./ProductCardList").then((mod) => mod.default)
);
export const ProductCardMore = dynamic<any>(() =>
  import("./ProductCardMore").then((mod) => mod.ProductCardMore)
);
export const ProductCategoryCard = dynamic<any>(() =>
  import("./ProductCategoryCard").then((mod) => mod.ProductCategoryCard)
);
export const ProductListBlock = dynamic<any>(() =>
  import("./ProductListBlock").then((mod) => mod.ProductListBlock)
);
export const ProductListHeroBlock = dynamic<any>(() =>
  import("./ProductListHeroBlock").then((mod) => mod.ProductListHeroBlock)
);
export const ProductListingBlockV2 = dynamic<any>(() =>
  import("./ProductListingBlockV2").then((mod) => mod.ProductListingBlockV2)
);
export const ProductPlanList = dynamic<any>(() =>
  import("./ProductPlanList").then((mod) => mod.ProductPlanList)
);
export const ProfileList = dynamic<any>(() =>
  import("./ProfileList").then((mod) => mod.ProfileList)
);
export const PromoBanner = dynamic<any>(() =>
  import("./PromoBanner").then((mod) => mod.PromoBanner)
);
export const QuickFact = dynamic<any>(() =>
  import("./QuickFact").then((mod) => mod.QuickFact)
);
export const Quote = dynamic<any>(() =>
  import("./Quote").then((mod) => mod.Quote)
);
export const RecentCardList = dynamic<any>(() =>
  import("./RecentCardList").then((mod) => mod.RecentCardList)
);
export const RelatedArticles = dynamic<any>(() =>
  import("./RelatedArticles").then((mod) => mod.default)
);
export const RelatedProducts = dynamic<any>(() =>
  import("./RelatedProducts").then((mod) => mod.RelatedProducts)
);
export const CSSearchAgent = dynamic<any>(() =>
  import("./SearchAgent").then((mod) => mod.CSSearchAgent)
);
export const SearchPage = dynamic<any>(() =>
  import("./SearchPage").then((mod) => mod.SearchPage)
);
export const Sitemap = dynamic<any>(() =>
  import("./Sitemap").then((mod) => mod.Sitemap)
);
export const Stepper = dynamic<any>(() =>
  import("./Stepper").then((mod) => mod.Stepper)
);
export const CSStepsCardSection = dynamic<any>(() =>
  import("./StepsCardSection").then((mod) => mod.CSStepsCardSection)
);
export const SupportBlock = dynamic<any>(() =>
  import("./SupportBlock").then((mod) => mod.SupportBlock)
);
export const TabMenu = dynamic<any>(() =>
  import("./TabMenu").then((mod) => mod.TabMenu)
);
export const CSTableContent = dynamic<any>(() =>
  import("./Table").then((mod) => mod.CSTableContent)
);
export const OneWebTable = dynamic<any>(() =>
  import("./Table").then((mod) => mod.OneWebTable)
);
export const CSTabContentSection = dynamic<any>(() =>
  import("./Tabs").then((mod) => mod.CSTabContentSection)
);
export const TagsBlock = dynamic<any>(() =>
  import("./TagsBlock").then((mod) => mod.TagsBlock)
);
export const Timeline = dynamic<any>(() =>
  import("./Timeline").then((mod) => mod.Timeline)
);
export const CSVideoCarouselContent = dynamic<any>(() =>
  import("./VideoCarouselContent").then((mod) => mod.CSVideoCarouselContent)
);
export const VideoContentBlock = dynamic<any>(() =>
  import("./VideoContentBlock").then((mod) => mod.VideoContentBlock)
);
export const CSFundPriceTabs = dynamic<any>(() =>
  import("./FundPriceTabs").then((mod) => mod.CSFundPriceTabs)
);
export const EntitySelectBlock = dynamic<any>(() =>
  import("./EntitySelectBlock").then((mod) => mod.EntitySelectBlock)
);
export const FeaturedCategoryTabs = dynamic<any>(() =>
  import("./FeaturedCategoryTabs").then((mod) => mod.default)
);
export const FeaturedCategoryProducts = dynamic<any>(() =>
  import("./FeaturedCategoryProducts").then(
    (mod) => mod.FeaturedCategoryProducts
  )
);
export const LeadFormContextInfo = dynamic<any>(() =>
  import("./LeadFormContextInfo").then((mod) => mod.LeadFormContextInfo)
);
export const SubNavigationBar = dynamic<any>(() =>
  import("./SubNavigationBar").then((mod) => mod.SubNavigationBar)
);
export const KeyFacts = dynamic<any>(() =>
  import("./KeyFacts").then((mod) => mod.KeyFacts)
);
export const MarketingCarouselBanner = dynamic<any>(() =>
  import("./MarketingCarouselBanner").then((mod) => mod.MarketingCarouselBanner)
);
export const InsuranceRecommendedProducts = dynamic<any>(() =>
  import("./InsuranceRecommendedProducts").then(
    (mod) => mod.InsuranceRecommendedProducts
  )
);
export const PartnerList = dynamic<any>(() =>
  import("./PartnerList").then((mod) => mod.PartnerList)
);
export const ProductCategoryBlock = dynamic<any>(() =>
  import("./ProductCategoryBlock").then((mod) => mod.ProductCategoryBlock)
);
export const CardList = dynamic<any>(() =>
  import("./CardList").then((mod) => mod.CardList)
);
export const CSArticleCardList = dynamic<any>(() =>
  import("./ArticleCardList").then((mod) => mod.CSArticleCardList)
);
export const CSCustomerReviewBlock = dynamic<any>(() =>
  import("./CustomerReviewBlock").then((mod) => mod.CSCustomerReviewBlock)
);
export const CSManagementTeamCard = dynamic<any>(() =>
  import("./ManagementTeamCard").then((mod) => mod.CSManagementTeamCard)
);
export const CSOneWebCardList = dynamic<any>(() =>
  import("./OneWebCardList").then((mod) => mod.CSOneWebCardList)
);
export const CSContentSectionMixOthers = dynamic<any>(() =>
  import("./OneWebContentBlock").then((mod) => mod.CSContentSectionMixOthers)
);
export const CSFeaturedCategoryProducts = dynamic<any>(() =>
  import("./FeaturedCategoryProducts").then(
    (mod) => mod.CSFeaturedCategoryProducts
  )
);
export const CSReminderBlock = dynamic<any>(() =>
  import("./Reminder").then((mod) => mod.CSReminderBlock)
);
export const CSRecentCardList = dynamic<any>(() =>
  import("./RecentCardList").then((mod) => mod.CSRecentCardList)
);
export const CSOneWebContentBlock = dynamic<any>(() =>
  import("./OneWebContentBlock").then((mod) => mod.CSOneWebContentBlock)
);
export const CSFooter = dynamic<any>(() =>
  import("./Footer").then((mod) => mod.CSFooter)
);
export const CSHeader = dynamic<any>(() =>
  import("./Header").then((mod) => mod.CSHeader)
);
export const CSCardList = dynamic<any>(() =>
  import("./CardList").then((mod) => mod.CSCardList)
);
export const CSLinkListBlock = dynamic<any>(() =>
  import("./LinkListBlock").then((mod) => mod.CSLinkListBlock)
);
export const CSCustomerSupportBlock = dynamic<any>(() =>
  import("./CustomerSupportBlock").then((mod) => mod.CSCustomerSupportBlock)
);
export const CSRecommendedProductsV2 = dynamic<any>(() =>
  import("./RecommendedProductsV2/RecommendedProductsV2").then(
    (mod) => mod.CSRecommendedProductsV2
  )
);
export const ReviewCarouselBanner = dynamic<any>(() =>
  import("./ReviewCarouselBanner").then((mod) => mod.ReviewCarouselBanner)
);
export const RecentCardListModern = dynamic<any>(() =>
  import("./RecentCardListModern").then((mod) => mod.RecentCardListModern)
);

export const CSAnimatedMarketingBlock = dynamic<any>(() =>
  import("./AnimatedMarketingBlock/AnimatedMarketingBlock").then(
    (mod) => mod.CSAnimatedMarketingBlock
  )
);

export const GridBlock = dynamic<any>(() =>
  import("./GridBlock").then((mod) => mod.GridBlock)
);

export const InsuranceInfo = dynamic<any>(() =>
  import("./InsuranceInfo").then((mod) => mod.InsuranceInfo)
);

export const InfographicFaq = dynamic<any>(() =>
  import("./InfographicFaq").then((mod) => mod.InfographicFaq)
);

export const CSTabbedContentSection = dynamic<any>(() =>
  import("./TabbedContentSection/TabbedContentSection").then(
    (mod) => mod.CSTabbedContentSection
  )
);

export const TaxCalculation = dynamic<any>(() =>
  import("./TaxCalculation").then((mod) => mod.TaxCalculation)
);

export const OmneFooter = dynamic<any>(() =>
  import("./OmneFooter").then((mod) => mod.OmneFooter)
);

export const CMSOmneWhatsNew = dynamic<any>(() =>
  import("./OmneWhatsNew").then((mod) => mod.CMSOmneWhatsNew)
);

export const OmneHeader = dynamic<any>(() =>
  import("./OmneHeader").then((mod) => mod.OmneHeader)
);

export const BackgroundVideoBanner = dynamic<any>(() =>
  import("./BackgroundVideoBanner").then((mod) => mod.BackgroundVideoBanner)
);

export const OmneWebCampaignBlock = dynamic<any>(() =>
  import("./OmneWebCampaignBlock").then((mod) => mod.OmneWebCampaignBlock)
);

export const OmneTopBanner = dynamic<any>(() =>
  import("./OmneTopBanner").then((mod) => mod.OmneTopBanner)
);

export const CSSectionTitle = dynamic<any>(() =>
  import("./SectionTitle").then((mod) => mod.CSSectionTitle)
);

export const CSFormSubmitted = dynamic<any>(() =>
  import("./FormSubmitted/FormSubmitted").then((mod) => mod.CSFormSubmitted)
);

export const UploadDocument = dynamic<any>(() =>
  import("./UploadDocument").then((mod) => mod.UploadDocument)
);

export const CmsClaimLandingBlock = dynamic<any>(() =>
  import("./ClaimLandingBlock/ClaimLandingBlock").then(
    (mod) => mod.CmsClaimLandingBlock
  )
);
export const CmsClaimErrorBlock = dynamic<any>(() =>
  import("./ClaimErrorBlock/ClaimErrorBlock").then(
    (mod) => mod.CmsClaimErrorBlock
  )
);
